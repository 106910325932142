import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import formatGuid from '../utils/format-guid';
import Badge from './Badge';
import Icon from './Icon';
import { Status } from '../types';

type Props = {
    id: string;
    campaignId: string;
    user: string;
    dateCreated: Date;
    lastModified: Date;
    status: Status;
    items: { cost: number }[];
};

const Order: React.FC<Props> = ({ id, dateCreated, user, lastModified, status, items }) => {
    const cost = items.map((i) => i.cost).reduce((acc, current) => acc + current);
    const handleOnClick = () => {};
    return (
        <OrderWrapper key={id} to={`/order/${id}`}>
            <Root onClick={handleOnClick}>
                <GridItem>{formatGuid(id)}</GridItem>
                {/* <GridItem>{format(new Date(dateCreated), "do 'of' MMMM y")}</GridItem> */}
                <EmailGridItem>{user}</EmailGridItem>
                <GridItem>{format(new Date(lastModified), "do 'of' MMMM y")}</GridItem>
                <GridItem>
                    <Badge type={status} />
                </GridItem>
                <GridItem>${cost}</GridItem>
            </Root>
        </OrderWrapper>
    );
};

const Root = styled.div`
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1.5fr 1fr 1.2fr 1fr;
    /* background: ${(p) => p.theme.colors.backgroundSecondary}; */
    padding: 24px 8px;
    border-bottom: 2px solid ${(p) => p.theme.colors.listDivider};
`;

const OrderWrapper = styled(Link)`
    /* display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center; */
    text-decoration: none;
    color: black;
    :visited {
        color: black;
    }
`;

const GridItem = styled.div`
    font-size: 14px;
`;

const EmailGridItem = styled(GridItem)`
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

export default Order;
