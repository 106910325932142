import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Header from '../components/Header';
import SelectMenu from '../components/SelectMenu';
import BackButton from '../components/Back';
import Textfield from '../components/Textfield';
import OrderedItem from '../components/OrderedItem';
import formatGuid from '../utils/format-guid';
import mapOptions from '../utils/string-array-to-select-menu-array';
import { SelectMenuValue } from '../types';
import { useAuth0 } from '../Auth';
import useCart from '../hooks/useCart';
import Button from '../components/Button';

const CREATE_ORDER = gql`
    mutation createOrder(
        $campaignId: String!
        $userId: String!
        $status: String!
        $items: [OrderItemsInput!]!
        $dateTime: Date!
        $amount: Float!
        $address: String!
        $city: String!
        $postcode: String!
        $state: String!
    ) {
        orderCreateOne(
            campaignId: $campaignId
            userId: $userId
            status: $status
            items: $items
            dateCreated: $dateTime
            lastModified: $dateTime
            amount: $amount
            address: $address
            city: $city
            postcode: $postcode
            state: $state
        ) {
            _id
        }
    }
`;

const GET_CAMPAIGN = (id: string) => gql`
        {
            campaignById(_id: "${id}") {
                name
                image
            }
        }
`;

const Shipping: React.FC<any> = () => {
    const { id: campaignId } = useParams();
    const { user } = useAuth0();
    const [createOrder, { data }] = useMutation(CREATE_ORDER);
    const { loading: campaignLoading, data: campaignData } = useQuery(GET_CAMPAIGN(campaignId));
    const [streetAddress, setStreetAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState<SelectMenuValue | undefined>();
    const [cart, setCart] = useCart();
    const history = useHistory();

    useEffect(() => {
        if (data) {
            history.push(`/thankyou/${data.orderCreateOne._id}`);
            return () => {
                setCart([]);
            };
        }
    }, [data, history]);

    const _states = ['NSW', 'QLD', 'VIC', 'WA', 'SA', 'NT', 'TAS', 'ACT'];
    const states = mapOptions(_states);

    const total =
        cart.length > 0
            ? cart
                  .map((i) => i.cost)
                  .reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;

    const handleStateChange = (newState: { value: string; label: string } | undefined) => {
        setState(newState);
    };

    const handleSubmitOrder = async () => {
        await createOrder({
            variables: {
                campaignId: campaignId,
                userId: user.email,
                status: 'created',
                items: cart,
                dateTime: new Date(),
                amount: total,
                address: streetAddress,
                postcode: postcode,
                city: city,
                state: state.value,
            },
        });
    };

    const disableButton = !streetAddress || !postcode || !city || !state || !cart;

    return (
        <Root>
            <HeaderWrapper campaignName={campaignData?.campaignById?.name}>
                <CampaignImage src={campaignLoading ? '' : campaignData.campaignById.image} />
                <Header>
                    {campaignLoading ? 'Campaign name' : campaignData.campaignById.name} - Finalise
                    Order
                </Header>
            </HeaderWrapper>
            <Main>
                <Order>
                    <Details>
                        <BackButton pop text="Go Back" />
                        <Header>Your order</Header>
                    </Details>
                    <TableHeader>
                        <TableHeaderItem>Item</TableHeaderItem>
                        <TableHeaderItem>Size</TableHeaderItem>
                        <TableHeaderItem>Colour</TableHeaderItem>
                        <TableHeaderItem>Quantity</TableHeaderItem>
                        <TableHeaderItem>Cost</TableHeaderItem>
                    </TableHeader>
                    {cart.map((i) => (
                        <OrderedItem key={i._id} {...i} id={i.id} />
                    ))}
                </Order>
                <ShippingWrapper>
                    <Details>
                        <Header>Shipping Information</Header>
                    </Details>
                    <Form>
                        <Textfield
                            value={streetAddress}
                            label="Street Address"
                            onChange={(val) => setStreetAddress(val)}
                        />
                        <TwoFieldsOneRow>
                            <Textfield value={city} label="City" onChange={(val) => setCity(val)} />
                            <MarginLeftTextField
                                value={postcode}
                                label="Postcode"
                                onChange={(val) => setPostcode(val)}
                            />
                        </TwoFieldsOneRow>
                        <SelectMenu
                            value={state ? [state] : []}
                            options={states}
                            label="State"
                            onChange={handleStateChange}
                        />
                    </Form>
                </ShippingWrapper>
            </Main>
            <ActionBar>
                <ConfirmButton
                    icon="mouse"
                    type="primary"
                    disabled={disableButton}
                    onClick={handleSubmitOrder}
                >
                    Confirm Order
                </ConfirmButton>
            </ActionBar>
        </Root>
    );
};

const Root = styled.div`
    /* padding: 16px; */
    padding-top: 0;
    margin-top: 0;
    position: relative;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    /* height: 100%; */
`;

const ShippingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-top: 24px;
    overflow: scroll;
    padding-top: 16px;
`;

const HeaderWrapper = styled.div<{ campaignName?: string }>`
    padding: 8px 16px;
    background-color: ${(p) => (p.campaignName === 'JAX Tyres' ? '#FFDE01' : undefined)};
    display: flex;
    align-items: center;
    /* margin: -16px -16px 0 16px; */
`;

const Form = styled.div`
    /* width: 50%; */
    height: 100%;
    overflow: scroll;
`;

const Main = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0 16px;
`;

const ActionBar = styled.div`
    display: flex;
    align-items: flex-end;
    border-top: 1px solid ${(p) => p.theme.colors.grey200};
    padding: 16px;
`;

const ConfirmButton = styled(Button)`
    margin-left: auto;
    font-size: 24px;
`;

const TwoFieldsOneRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

const Order = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-right: 16px;
    padding-top: 16px;
    overflow: scroll;
    border-right: 1px solid ${(p) => p.theme.colors.grey200};
    padding-right: 16px;
`;

const MarginLeftTextField = styled(Textfield)`
    margin-left: 16px;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    /* margin: 0 16px; */
    grid-column-gap: 16px;
    background: ${(p) => p.theme.colors.backgroundSecondary};
`;

const TableHeaderItem = styled.span`
    font-size: 12px;
`;

const OrderableItems = styled.div`
    width: 100%;
`;

const Details = styled.div`
    /* padding: 0 16px; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
`;

const CampaignImage = styled.img`
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 4px;
`;

export default Shipping;
