import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from './Button';
import Textfield from './Textfield';
import ImageUploader from '../components/ImageUploader';
import SelectMenu from './SelectMenu';

const CAMPAIGNS = gql`
    {
        campaignMany(filter: {}) {
            _id
            name
        }
    }
`;

const ADD_USER = gql`
    mutation addUser($name: String!, $email: String!, $campaignId: String!) {
        userCreateOne(name: $name, email: $email, campaignId: $campaignId) {
            name
            email
            campaignId
            _id
        }
    }
`;

type Props = {
    onAdd(): void;
};

const AddUser: React.FC<Props> = ({ onAdd }) => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [campaign, setCampaign] = useState<{ value: string; label: string } | undefined>();
    const [creating, setCreating] = useState(false);
    const [addUser, { data }] = useMutation(ADD_USER);
    const { loading, error, data: campaigns } = useQuery(CAMPAIGNS);

    useEffect(() => {
        if (data) {
            onAdd();
            return () => {
                setCreating(false);
            };
        }
    }, [data, history]);

    const handleAddUser = () => {
        setCreating(true);
        addUser({
            variables: { name: name.trim(), email: email.trim(), campaignId: campaign.value },
        });
    };

    const handleChangeCampaign = (value: { value: string; label: string }) => {
        setCampaign(value);
    };

    const campaignsMapped =
        campaigns &&
        campaigns.campaignMany.map((c) => {
            return { value: c._id, label: c.name };
        });

    return (
        <Root>
            <Instructions>
                Add your customers name and email address to invite them to order.
            </Instructions>
            <Textfield
                value={name}
                placeholder="John Doe"
                label="Full Name"
                onChange={(value) => setName(value)}
            />
            <Textfield
                value={email}
                placeholder="john.doe@gmail.com"
                label="Email Address"
                onChange={(value) => setEmail(value)}
            />
            <SelectMenu
                value={[campaign]}
                options={loading ? [] : campaignsMapped}
                label="Campaign"
                onChange={handleChangeCampaign}
            />
            {creating && <p>Creating...</p>}
            <StyledButton type="primary" onClick={handleAddUser}>
                Invite
            </StyledButton>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-content: space-between;
`;

const Instructions = styled.span`
    font-size: 14px;
`;

const StyledButton = styled(Button)`
    margin-top: 24px;
`;

export default AddUser;
