import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Header from '../components/Header';
import Modal from '../components/Modal';
import CreateCampaign from '../components/CreateCampaign';

const CAMPAIGNS = gql`
    {
        campaignMany(filter: {}) {
            _id
            name
            image
        }
    }
`;
const Campaigns: React.FC<any> = () => {
    const [creatingCampaign, setCreatingCampaign] = useState(false);
    const { loading, error, data } = useQuery(CAMPAIGNS, { fetchPolicy: 'network-only' });

    const renderCampaigns = () => {
        return data.campaignMany.map(
            ({ name, _id, image }: { name: string; _id: string; image: string }) => (
                <CampaignWrapper key={_id} to={`/campaign/${_id}`}>
                    <Campaign>
                        <CampaignImage src={image} />
                    </Campaign>
                    <CampaignName>{name}</CampaignName>
                </CampaignWrapper>
            ),
        );
    };

    const handleClickCreateNew = () => {
        setCreatingCampaign(true);
    };

    const handleCloseModal = () => {
        setCreatingCampaign(false);
    };

    return (
        <Root>
            <Header>Campaigns</Header>
            {loading && <p>Loading...</p>}
            {error && <p>Error :^(</p>}
            {!!data && <CampaignGrid>{renderCampaigns()}</CampaignGrid>}
            <CreateNew onClick={handleClickCreateNew}>+ Create New Campaign</CreateNew>
            <Modal open={creatingCampaign} title="Create Campaign" onClose={handleCloseModal}>
                <CreateCampaign />
            </Modal>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 16px 16px;
    /* margin: 0 auto; */
    position: relative;
`;

const CampaignGrid = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fit, 258px);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-top: 24px;
    /* border: 1px solid black; */
`;

const CreateNew = styled.span`
    position: absolute;
    line-height: 38px;
    right: 0;
    top: 0;
    cursor: pointer;
    /* text-decoration: underline; */
    color: ${(p) => p.theme.colors.blue};
    :visited {
        color: black;
    }
`;

const CampaignWrapper = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    text-decoration: none;
    color: black;
    :visited {
        color: black;
    }
`;

const CampaignImage = styled.img`
    width: 256px;
`;

const Campaign = styled.div`
    display: flex;
    align-items: center;
    height: 256px;
    width: 256px;
    border: 1px solid #e5e5e5;
    border-radius: 32px;
    box-shadow: 3px 3px 12px -6px rgba(92, 92, 92, 1);
    overflow: hidden;
`;

const CampaignName = styled.h4`
    margin: 0;
    margin-top: 16px;
`;

export default Campaigns;
