import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Badge from '../components/Badge';
import Back from '../components/Back';
import Button from '../components/Button';
import Header from '../components/Header';
import OrderTracking from '../components/OrderTracking';
import OrderedItem from '../components/OrderedItem';
import { getActionForStatus, cancelOrder } from '../utils/orders';
import formatGuid from '../utils/format-guid';
import { useAuth0 } from '../Auth';
import Textfield from '../components/Textfield';

const Order: React.FC<any> = () => {
    const { isAdmin } = useAuth0();
    const { id } = useParams();
    const ORDER_QUERY = gql`
    {
      orderById(_id: "${id}"){
        _id
        items {
            _id
            id
            name
            image
            size
            colour
            quantity
            cost
        }
        lastModified
        dateCreated
        campaignId
        status
        userId
        trackingId
        trackingProvider
        address
        postcode
        city
		state
      }
    }
  `;

    const { loading, data, refetch } = useQuery(ORDER_QUERY);

    const status = data && data.orderById && data.orderById.status;

    const renderActionBar = () => {
        if (!data || !data.orderById) return null;
        const actionBarValue = getActionForStatus(data.orderById.status);
        return (
            <ActionBar>
                {actionBarValue && (
                    <Buttons>
                        <Button
                            onClick={async () => {
                                await cancelOrder(id);
                                refetch();
                            }}
                        >
                            Cancel Order
                        </Button>
                        {isAdmin && (
                            <PrimaryAction
                                type="primary"
                                onClick={async () => {
                                    await actionBarValue.fn(id);
                                    refetch();
                                }}
                            >
                                {actionBarValue.action}
                            </PrimaryAction>
                        )}
                    </Buttons>
                )}
            </ActionBar>
        );
    };

    const items = !loading && data && data.orderById && data.orderById.items;

    const total =
        items.length > 0
            ? items
                  .map((i) => i.cost)
                  .reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;

    const finishedLoading = !loading && data && data.orderById;
    const displayTracking =
        data &&
        data.orderById &&
        data.orderById.status &&
        (data.orderById.status === 'awaiting shipping' || data.orderById.status === 'in transit');

    return (
        <Root>
            <Main
                isAdmin={isAdmin || status === 'created'}
                showTracking={finishedLoading && displayTracking}
            >
                <Back text="Go Back" pop />
                <TopBar>
                    <Header>
                        Order: {loading ? 'xxx-xxx-xxx' : formatGuid(data.orderById._id)}
                    </Header>
                    {!loading && (
                        <BadgeWrapper>
                            <StatusText>Order Status:</StatusText>
                            <Badge type={data.orderById.status}>{data.orderById.status}</Badge>
                        </BadgeWrapper>
                    )}
                </TopBar>
                <TotalWrapper>
                    {!loading && <OrderedBy>Ordered by: {data.orderById.userId}</OrderedBy>}
                    <Total>Total: ${total}</Total>
                </TotalWrapper>
                {/* <OrderNumber>Order: {loading ? 'xxx-xxx-xxx' : data.orderById._id}</OrderNumber> */}
                {/* {JSON.stringify(data)} */}
                <TableHeader>
                    <TableHeaderItem>Item</TableHeaderItem>
                    <TableHeaderItem>Size</TableHeaderItem>
                    <TableHeaderItem>Colour</TableHeaderItem>
                    <TableHeaderItem>Quantity</TableHeaderItem>
                    <TableHeaderItem>Cost</TableHeaderItem>
                </TableHeader>
                {!loading &&
                    data.orderById.items.map((i) => <OrderedItem key={i._id} {...i} id={i.id} />)}
                {finishedLoading && (
                    <Shipping>
                        <Header>Shipping Information</Header>
                        <Textfield
                            value={data.orderById.address}
                            label="Address"
                            onChange={() => {}}
                        />
                        <Textfield
                            value={data.orderById.postcode}
                            label="Postcode"
                            onChange={() => {}}
                        />
                        <Textfield value={data.orderById.city} label="City" onChange={() => {}} />
                        <Textfield value={data.orderById.state} label="State" onChange={() => {}} />
                    </Shipping>
                )}
            </Main>
            {(isAdmin || status === 'created') && finishedLoading && (
                <ActionBars>
                    {displayTracking && (
                        <ActionBar>
                            <OrderTracking
                                status={data.orderById.status}
                                orderId={data.orderById._id}
                                trackingId={data.orderById.trackingId}
                                trackingProvider={data.orderById.trackingProvider}
                            />
                        </ActionBar>
                    )}
                    {renderActionBar()}
                </ActionBars>
            )}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Main = styled.div<{ isAdmin: boolean; showTracking: boolean }>`
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 16px 16px;
    margin-bottom: ${(p) => (!p.isAdmin ? undefined : p.showTracking ? '164px' : '65px')};
    overflow: scroll;
    @media print {
        overflow: visible !important;
    }
`;

const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const OrderedBy = styled.span`
    margin-bottom: 16px;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    /* margin: 0 16px; */
    grid-column-gap: 16px;
    background: ${(p) => p.theme.colors.backgroundSecondary};
`;

const TableHeaderItem = styled.span`
    font-size: 12px;
`;

const ActionBars = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    left: 54px;
    right: 0;
    background: white;
    @media print {
        display: none;
    }
`;

const ActionBar = styled.div`
    display: flex;
    justify-content: flex-end;

    border-top: 1px solid #e5e5e5;
    width: 100%;
    /* padding: 16px; */
`;

const TotalWrapper = styled.div`
    display: flex;
    flex-direction: row;

    padding: 16px;
    padding-left: 0;
    /* margin: 0 16px; */
`;

const Total = styled.span`
    font-weight: 600;
    margin-left: auto;
`;

const PrimaryAction = styled(Button)`
    margin-left: 8px;
`;

const Buttons = styled.div`
    display: flex;
    margin: 16px;
`;

const BadgeWrapper = styled.div``;

const StatusText = styled.span`
    margin-right: 8px;
    font-weight: 600;
`;

const Shipping = styled.div`
    margin-bottom: 16px;
    border-top: 1px solid #e5e5e5;
`;

export default Order;
