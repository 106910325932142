import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import SelectMenu from './SelectMenu';
import TextField from './Textfield';
import { Status } from '../types';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

type Props = {
    status: Status;
    orderId: string;
    trackingId: string | undefined;
    trackingProvider: string | undefined;
};

const EDIT_ORDER = gql`
    mutation updateTrackingOnOrder(
        $orderId: MongoID!
        $trackingId: String!
        $trackingProvider: String!
    ) {
        orderUpdateById(
            record: { _id: $orderId, trackingId: $trackingId, trackingProvider: $trackingProvider }
        ) {
            record {
                _id
            }
        }
    }
`;

const OrderTracking: React.FC<Props> = ({ orderId, trackingId, trackingProvider }) => {
    const [id, setId] = useState(trackingId);
    const [provider, setProvider] = useState(trackingProvider);
    const [updating, setUpdating] = useState(false);
    const [update, setUpdate] = useState(false);
    const [updateOrder, { data }] = useMutation(EDIT_ORDER);

    useEffect(() => {
        if (trackingId && trackingProvider) {
            setUpdate(true);
        }
    }, []);

    useEffect(() => {
        if (data && data.orderUpdateById.record) {
            setUpdating(false);
            setUpdate(true);
        }
    }, [data]);

    const PROVIDER_OPTIONS = [
        { label: 'Toll', value: 'toll' },
        { label: 'Fastway', value: 'fastway' },
    ];

    const capitalise = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const handleIdChange = (value: string) => {
        setId(value);
    };
    const handleProviderChange = (option: { value: string; label: string }) => {
        setProvider(option.value);
    };

    const handleOnClick = () => {
        setUpdating(true);
        updateOrder({
            variables: {
                orderId: orderId,
                trackingProvider: provider,
                trackingId: id,
            },
        });
    };

    return (
        <Root>
            <ProviderSelectMenu
                label="Shipping Provider"
                value={provider ? [{ label: capitalise(provider), value: provider }] : []}
                options={PROVIDER_OPTIONS}
                onChange={handleProviderChange}
            />
            <TrackingTextField
                label="Tracking Number"
                placeholder="Tracking Number..."
                value={id}
                onChange={handleIdChange}
            />
            <SubmitButton busy={updating} disabled={!id || !provider} onClick={handleOnClick}>
                {update ? 'Update' : 'Add'} Tracking
            </SubmitButton>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px;
    & > * {
        margin-left: 8px;
    }
`;

const ProviderSelectMenu = styled(SelectMenu)`
    margin-top: 0;
`;

const TrackingTextField = styled(TextField)`
    margin-top: 0;
`;

const SubmitButton = styled(Button)`
    margin-top: auto;
    height: 38px;
`;

export default OrderTracking;
