import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/Header';
import formatGuid from '../utils/format-guid';
import Button from '../components/Button';

const Thankyou: React.FC<any> = () => {
    const history = useHistory();
    const { id } = useParams();
    return (
        <Root>
            <Header>Thank you for your order!</Header>
            <OrderNumber>Order Number: {formatGuid(id)}</OrderNumber>
            <Text>You will receive an email confirming your order shortly.</Text>
            <Text>
                Once our team has prepared your order, you will be invoiced and will receive
                dispatch details.
            </Text>
            <Text>The Ideal Promotions Team.</Text>
            <Button onClick={() => history.push(`/order/${id}`)}>Take me to my order</Button>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 16px;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const OrderNumber = styled.p`
    font-weight: 600;
    font-size: 26px;
`;

const Text = styled.p`
    margin: 24px 0;
`;

export default Thankyou;
