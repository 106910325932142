import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import ApolloClient, { InMemoryCache, defaultDataIdFromObject } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import PrivateRoute from './components/PrivateRoute';
import CreateOrder from './routes/CreateOrder';
import Campaigns from './routes/Campaigns';
import Campaign from './routes/Campaign';
import Home from './routes/Home';
import Order from './routes/Order';
import Orders from './routes/Orders';
import Users from './routes/Users';
import Shipping from './routes/Shipping';
import InvalidRoute from './routes/404';
import history from './utils/history';
import Layout from './components/Layout';
import { CartProvider } from './hooks/useCart';
import Theme from './Theme';
import Thankyou from './routes/Thankyou';

const App: React.FC = () => {
    const GlobalStyle = createGlobalStyle`
        html {
            font-family: -apple-system, BlinkMacSystemFont,
            “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
            “Fira Sans”, “Droid Saa”, “Helvetica Neue”,
            sans-serif;
            height: 100%;
        }
        body {
            margin: 0;
            height: 100%;
        }
        #root {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
  `;
    const client = new ApolloClient({
        // uri: 'http://localhost:4000/graphql',
        uri: 'https://j7jio43s9k.execute-api.ap-southeast-2.amazonaws.com/prod/graphql',
        cache: new InMemoryCache({
            dataIdFromObject: (value) => {
                // @ts-ignore
                if (value._id) {
                    // @ts-ignore
                    return `${value.__typename}:${value._id}`;
                }
                return defaultDataIdFromObject(value);
            },
        }),
    });

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={Theme['light']}>
                <Router history={history}>
                    <Layout>
                        <CartProvider>
                            <Switch>
                                {/* <PrivateRoute path={'/signup/:id'} component={Campaign} /> */}
                                <Route path={`/campaign/:id/order`}>
                                    <CreateOrder />
                                </Route>
                                <PrivateRoute path={`/campaign/:id`} component={Campaign} admin />
                                <PrivateRoute path="/orders" component={Orders} admin />
                                <PrivateRoute path="/users" component={Users} admin />
                                <Route path={'/order/:id'} component={Order} />
                                <Route path={`/confirm/:id`} component={Shipping} />
                                <Route path={`/thankyou/:id`} component={Thankyou} />
                                <Route path={'/'} exact component={Home} />
                                <Route component={InvalidRoute} />
                            </Switch>
                        </CartProvider>
                    </Layout>
                </Router>
            </ThemeProvider>
            <GlobalStyle />
        </ApolloProvider>
    );
};

export default App;
