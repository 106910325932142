import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import InvalidRoute from '../routes/404';
import LoadingRoute from '../routes/Loading';
import { useAuth0 } from '../Auth';

type Props = {
    component: React.FC<any>;
    admin?: boolean;
    path?: string | string[] | undefined;
};

const PrivateRoute = ({ component: Component, path, admin, ...rest }: Props) => {
    const { loading, isAuthenticated, isAdmin, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const verified = admin ? isAdmin === true : isAuthenticated === true;

    const render = (props: any) =>
        loading ? <LoadingRoute /> : verified ? <Component {...props} /> : <InvalidRoute />;

    return <Route path={path} component={render} {...rest} />;
};

export default PrivateRoute;
