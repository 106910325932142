import React from 'react';
import styled from 'styled-components';

type Props = {
    items: Array<JSX.Element>;
    loading: boolean;
    emptyState: string;
    className?: string;
};

const List: React.FC<Props> = ({ items, loading, emptyState, className }) => {
    return (
        <Root className={className}>
            {loading ? (
                <>Loading...</>
            ) : (
                <>
                    {items.map((item) => (
                        <ListItem key={item.key}>{item}</ListItem>
                    ))}
                    {items.length < 1 && !loading && <EmptyState>{emptyState}</EmptyState>}
                </>
            )}
        </Root>
    );
};

const Root = styled.div`
    overflow-y: scroll;
`;

const ListItem = styled.div``;

const EmptyState = styled.div`
    margin-top: 8px;
    margin-left: 8px;
`;

export default List;
