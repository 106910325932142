import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import Header from '../components/Header';
import Campaigns from './Campaigns';
import UserDashboard from './UserDashboard';
import { useAuth0 } from '../Auth';

const Home: React.FC<any> = ({ ...rest }) => {
    const { loading, isAuthenticated, isAdmin, loginWithRedirect } = useAuth0();
    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect]);

    const render = (props: any) =>
        isAdmin ? <Campaigns {...props} /> : <UserDashboard {...props} />;

    return <Route path={'/'} component={render} {...rest} />;
};

export default Home;
