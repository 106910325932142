import React from 'react';
import styled from 'styled-components';

type Props = {
    gridTemplateColumns: string;
};

const TableHeading: React.FC<Props> = ({ children, gridTemplateColumns }) => {
    return <Root gridTemplateColumns={gridTemplateColumns}>{children}</Root>;
};

const Root = styled.div<{ gridTemplateColumns: string }>`
    display: grid;
    flex-direction: row;
    grid-template-columns: ${(p) => p.gridTemplateColumns};
    background: ${(p) => p.theme.colors.backgroundSecondary};
    padding: 8px;
`;

export default TableHeading;
