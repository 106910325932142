import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Orders from '../components/Orders';
import { useAuth0 } from '../Auth';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const UserDashboard: React.FC<any> = () => {
    const { loading: userLoading, user } = useAuth0();
    const history = useHistory();
    const USER_QUERY = gql`
    {
        userMany(filter: { email:"${user && user.name}"}){
            name
            email
            _id
            campaignId
        }
    }
    `;

    const handleCreateOrder = () => {
        history.push(`/campaign/${data.userMany[0].campaignId}/order`);
    };

    const { loading, error, data, refetch } = useQuery(USER_QUERY, { fetchPolicy: 'network-only' });

    const campaignId =
        (!loading && data && data.userMany.length > 0 && data.userMany[0].campaignId) || '';

    const CAMPAIGN_QUERY = gql`
    {
        campaignById(_id: "${campaignId}"){
            name
            image
        }
    }
    `;

    const {
        loading: _campaignLoading,
        error: campaignErr,
        data: campaignData,
    } = useQuery(CAMPAIGN_QUERY, { fetchPolicy: 'network-only' });

    useEffect(() => {
        console.log('refetching orders');
        refetch();
    });

    const campaignLoading = _campaignLoading || campaignData.campaignById === null;

    return (
        <Root>
            <Details campaignName={campaignData?.campaignById?.name}>
                <CampaignImage src={campaignLoading ? '' : campaignData.campaignById.image} />
                <Header>
                    {campaignLoading ? 'Campaign name' : campaignData.campaignById.name}
                </Header>
            </Details>
            <StyledHeader>
                Welcome {data && data.userMany && data.userMany[0] && data.userMany[0].name}
            </StyledHeader>
            <CreateNew onClick={handleCreateOrder}>+ Create Order</CreateNew>
            {data && data.userMany && data.userMany[0] && (
                <StyledOrdersList userId={data.userMany[0].email} />
            )}
        </Root>
    );
};

const Root = styled.div`
    position: relative;
    padding: 16px 16px;
    height: 100%;
    width: 100%;
    overflow: scroll;
`;

const Details = styled.div<{ campaignName?: string }>`
    /* padding: 0 16px; */
    display: flex;
    align-items: center;
    background-color: ${(p) => (p.campaignName === 'JAX Tyres' ? '#FFDE01' : undefined)};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 8px 16px;
`;

const CampaignImage = styled.img`
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 4px;
`;

const CreateNew = styled.span`
    position: absolute;
    line-height: 38px;
    right: 16px;
    top: 24px;
    cursor: pointer;
    /* text-decoration: underline; */
    color: ${(p) => p.theme.colors.blue};
    :visited {
        color: black;
    }
`;

const StyledOrdersList = styled(Orders)`
    margin: 0 -16px;
    /* margin: 0 16px; */
    margin-bottom: 16px;
`;

const StyledHeader = styled(Header)`
    margin-top: 96px;
`;

export default UserDashboard;
