import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Order from './Order';
import TableHeading from './TableHeading';
import Header from '../components/Header';
import Textfield from './Textfield';
import SelectMenu from './SelectMenu';
import mapToOptions from '../utils/string-array-to-select-menu-array';
import { useAuth0 } from '../Auth';

type Props = {
    campaignId?: string;
    userId?: string;
    all?: boolean;
    className?: string;
};

const Orders: React.FC<Props> = ({ campaignId, userId, all, className }) => {
    const [search, setSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState();
    const { isAdmin } = useAuth0();
    const queryString = `${campaignId ? `campaignId: "${campaignId}",` : ''}${
        userId ? `userId: "${userId}"` : ''
    }`;
    const ORDERS_QUERY = gql`
    {
        orderMany(filter: { ${queryString} }, sort: _ID_DESC, limit: 100) {
            _id
            lastModified
            dateCreated
            status
            campaignId
            userId
            items {
                cost
            }
        }
    }
    `;

    const ALL_ORDERS_QUERY = gql`
        {
            orderMany(filter: {}, sort: _ID_DESC, limit: 100) {
                _id
                lastModified
                dateCreated
                status
                campaignId
                userId
                items {
                    cost
                }
            }
        }
    `;

    const { loading, data } = useQuery(all ? ALL_ORDERS_QUERY : ORDERS_QUERY);

    const orderSortOrder = [
        'created',
        'pending',
        'payment requested',
        'awaiting shipping',
        'in transit',
        'complete',
        'cancelled',
    ];

    const customSort = ({ data, sortBy, sortField }) => {
        const sortByObject = sortBy.reduce((obj, item, index) => {
            return {
                ...obj,
                [item]: index,
            };
        }, {});
        return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
    };

    const orderedOrders =
        !loading &&
        data &&
        data.orderMany &&
        data.orderMany.length > 0 &&
        customSort({ data: data.orderMany, sortBy: orderSortOrder, sortField: 'status' });

    const filterResults = (orders) => {
        let filteredResults = orders;

        if (search) {
            filteredResults = filteredResults.filter(
                (o) =>
                    o.userId.startsWith(search) ||
                    o.userId.includes(search) ||
                    o._id.includes(search),
            );
        }

        if (statusFilter) {
            filteredResults = filteredResults.filter((o) => o.status === statusFilter.value);
        }

        return filteredResults;
    };

    const results = filterResults(orderedOrders);

    return (
        <Root className={className}>
            <HeaderWrapper>
                <TitleWrapper>
                    {!all ? <Title>Orders</Title> : <Header>All Orders</Header>}
                </TitleWrapper>
                {isAdmin && (
                    <Filters>
                        <FilterText
                            label="Filter by Text"
                            placeholder="Filter by email or order ID"
                            value={search}
                            onChange={(v) => setSearch(v)}
                        />
                        <StatusFilter
                            label="Filter by Status"
                            value={[statusFilter] || undefined}
                            options={mapToOptions(orderSortOrder)}
                            isClearable
                            onChange={(o) => setStatusFilter(o)}
                        />
                    </Filters>
                )}
            </HeaderWrapper>
            {loading && <>Loading...</>}
            {!loading && data.orderMany.length > 0 && (
                <TableHeading gridTemplateColumns="1fr 1.5fr 1fr 1.2fr 1fr">
                    <HeaderItem>Order #</HeaderItem>
                    <HeaderItem>Buyer</HeaderItem>
                    <HeaderItem>Last Modified</HeaderItem>
                    <HeaderItem>Status</HeaderItem>
                    <HeaderItem>Amount</HeaderItem>
                </TableHeading>
            )}
            {!loading &&
                results &&
                results.map((o) => (
                    <Order
                        key={o._id}
                        id={o._id}
                        user={o.userId}
                        campaignId={o.campaignId}
                        dateCreated={o.dateCreated}
                        lastModified={o.lastModified}
                        status={o.status}
                        items={o.items}
                    />
                ))}
            {!loading && data.orderMany.length === 0 && <>No orders...</>}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 70%;
    padding: 0 16px;
    /* min-width: 70%; */
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px 0;
`;

const Filters = styled.div`
    display: flex;
    flex: 1 1;
`;

const FilterText = styled(Textfield)`
    margin-top: 0;
    margin-right: 16px;
`;

const StatusFilter = styled(SelectMenu)`
    margin-top: 0;
    flex: 1;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex: 1 1;
`;

const Title = styled.h2``;

const HeaderItem = styled.div`
    font-size: 12px;
`;

export default Orders;
