import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Cart from '../components/Cart';
import OrderableItem from '../components/OrderableItem';
import { OrderItem } from '../types';
import { gql } from 'apollo-boost';
import useCart from '../hooks/useCart';
import Modal from '../components/Modal';

const GET_CAMPAIGN = (id: string) => gql`
        {
            itemMany(filter: { campaignId: "${id}" }) {
                _id
                name
                image
                price
                sizes
                colours
                deleted
            }
            campaignById(_id: "${id}") {
                name
                image
            }
        }
`;

const CreateOrder: React.FC<any> = () => {
    const [order, setOrder] = useState<OrderItem[]>([]);
    const [cart, setCart] = useCart();
    const { id } = useParams();
    const { loading, data } = useQuery(GET_CAMPAIGN(id));
    const [modal, setModal] = useState<{ title: string; image: string } | undefined>();

    const handleAddToCart = (newOrder: OrderItem) => {
        const matchingItemInCartIndex = cart.findIndex((o) => {
            return o.id === newOrder.id && o.colour === newOrder.colour && o.size === newOrder.size;
        });
        if (matchingItemInCartIndex === -1) {
            setCart((o) => [...o, newOrder]);
            return;
        }
        const currentOrderItem = cart[matchingItemInCartIndex];
        const newOrderObject = {
            ...currentOrderItem,
            quantity: currentOrderItem.quantity + newOrder.quantity,
            cost: (currentOrderItem.quantity + newOrder.quantity) * newOrder.price,
        };
        const newOrderArray = [
            ...cart.slice(0, matchingItemInCartIndex),
            newOrderObject,
            ...cart.slice(matchingItemInCartIndex + 1, cart.length),
        ];
        setCart(newOrderArray);
    };

    const handleSubmitOrder = () => {
        setCart([]);
    };

    const handleRemoveItem = (index: number) => {
        setCart((orders) => [...orders.slice(0, index), ...orders.slice(index + 1)]);
    };

    const handleClickImage = (value: { title: string; image: string }) => {
        setModal(value);
    };

    return (
        <Root>
            <Main>
                <Details campaignName={data?.campaignById?.name}>
                    <CampaignImage src={loading ? '' : data.campaignById.image} />
                    <Header>
                        {loading ? 'Campaign name' : data.campaignById.name} - New Order
                    </Header>
                </Details>
                <TableHeader>
                    <TableHeaderItem>Item</TableHeaderItem>
                    <TableHeaderItem>Size</TableHeaderItem>
                    <TableHeaderItem>Colour</TableHeaderItem>
                    <TableHeaderItem>Quantity</TableHeaderItem>
                    <TableHeaderItem>Cost</TableHeaderItem>
                    <TableHeaderItem></TableHeaderItem>
                </TableHeader>
                <OrderableItems>
                    {!loading &&
                        data &&
                        data.itemMany &&
                        data.itemMany
                            .filter((i) => !i.deleted)
                            .map((i) => (
                                <OrderableItem
                                    key={i._id}
                                    item={i}
                                    id={i._id}
                                    onAddToCart={handleAddToCart}
                                    onClickImage={handleClickImage}
                                />
                            ))}
                </OrderableItems>
            </Main>
            <Cart
                campaignId={id}
                onRemoveItem={handleRemoveItem}
                onSubmitOrder={handleSubmitOrder}
            />
            {modal && (
                <Modal title={modal.title} open={true} onClose={() => setModal(undefined)}>
                    <ImageWrapper>
                        <Preview src={modal.image} />
                    </ImageWrapper>
                </Modal>
            )}
        </Root>
    );
};

const Root = styled.div`
    /* margin: 16px 16px; */
    margin-top: 0;
    position: relative;
    display: flex;
    flex: 1 0 30%;
    flex-direction: row;
    height: 100%;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    margin: 0 16px;
    margin-top: 16px;
    /* margin: 0 16px; */
    grid-column-gap: 16px;
    background: ${(p) => p.theme.colors.backgroundSecondary};
`;

const TableHeaderItem = styled.span`
    font-size: 12px;
`;

const Main = styled.div`
    width: 75%;
    /* padding: 0 16px; */
    overflow: scroll;
`;

const OrderableItems = styled.div`
    /* width: 100%; */
    margin: 0 16px;
`;

const Details = styled.div<{ campaignName?: string }>`
    padding: 8px 16px;
    background-color: ${(p) => (p.campaignName === 'JAX Tyres' ? '#FFDE01' : undefined)};
    display: flex;
    align-items: center;
    /* margin: -16px -16px 0 16px; */
`;

const CampaignImage = styled.img`
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 4px;
`;

const ImageWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
`;

const Preview = styled.img`
    width: 400px;
    height: auto;
`;

export default CreateOrder;
