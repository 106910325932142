import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Button from './Button';
import Textfield from './Textfield';
import ImageUploader from '../components/ImageUploader';

const CREATE_CAMPAIGN = gql`
    mutation createCampaign($name: String!, $image: String) {
        campaignCreateOne(record: { name: $name, image: $image }) {
            record {
                _id
                name
            }
        }
    }
`;

const CreateCampaign: React.FC<any> = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [creating, setCreating] = useState(false);
    const [createCampaign, { data }] = useMutation(CREATE_CAMPAIGN);

    useEffect(() => {
        if (data) {
            history.push(`/campaign/${data.campaignCreateOne.record._id}`);
            return () => {
                setCreating(false);
            };
        }
    }, [data, history]);

    const handleCreateCampaign = () => {
        setCreating(true);
        createCampaign({ variables: { name: name, image: image, items: [] } });
    };

    const handleUpload = (image: string) => {
        setImage(image);
    };

    return (
        <Root>
            <Instructions>
                Give your customer's campaign a name and an image to get started.
            </Instructions>
            <Textfield
                value={name}
                placeholder="Name of Campaign"
                label="Campaign Name"
                onChange={(value) => setName(value)}
            />
            <ImageUploader value={image} onUpload={handleUpload} onClear={() => setImage('')} />
            {creating && <p>Creating...</p>}
            <StyledButton type="primary" onClick={handleCreateCampaign}>
                Create
            </StyledButton>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-content: space-between;
`;

const Instructions = styled.span`
    font-size: 14px;
`;

const StyledButton = styled(Button)`
    margin-top: 24px;
`;

export default CreateCampaign;
