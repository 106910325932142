import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import SelectMenu from './SelectMenu';
import Textfield from './Textfield';
import Numberfield from './Numberfield';
import mapOptions from '../utils/string-array-to-select-menu-array';
import { OrderItem, SelectMenuValue } from '../types';

type Props = {
    item: {
        name: string;
        price: number;
        image: string;
        sizes: string[];
        colours: string[];
    };
    id: string;
    size?: string;
    colour?: string;
    cost?: number;
    quantity?: number;
    readOnly?: boolean;
    onAddToCart?(order: OrderItem): void;
    onClickImage(value: { title: string; image: string }): void;
};

const OrderableItem: React.FC<Props> = ({
    id,
    item,
    onAddToCart,
    size: initialSize,
    colour: initialColour,
    cost: initialCost,
    quantity: initialQuantity,
    onClickImage,
}) => {
    const [size, setSize] = useState<SelectMenuValue | undefined>(
        initialSize ? { label: initialSize, value: initialSize } : undefined,
    );
    const [colour, setColour] = useState<SelectMenuValue | undefined>(
        initialColour ? { label: initialColour, value: initialColour } : undefined,
    );
    const [cost, setCost] = useState<number>(initialCost || undefined);
    const [quantity, setQuantity] = useState(initialQuantity || 0);

    const { name, price, image, sizes, colours } = item;

    useEffect(() => {
        if (quantity && price) {
            setCost(quantity * price);
        } else {
            setCost(0);
        }
    }, [quantity, price]);

    const handleSizeChange = (newSize: { value: string; label: string } | undefined) => {
        setSize(newSize);
    };

    const handleColourChange = (newColour: { value: string; label: string } | undefined) => {
        setColour(newColour);
    };

    const handleQuantityChange = (value: number | undefined) => {
        setQuantity(value);
    };

    const resetFields = () => {
        setSize(undefined);
        setColour(undefined);
        setQuantity(0);
    };

    const handleClickAdd = () => {
        const order = {
            id,
            name,
            image,
            cost,
            size: size.value,
            colour: colour.value,
            quantity,
            price,
        };
        resetFields();
        onAddToCart(order);
    };

    const handleClickImage = () => {
        onClickImage({ title: name, image: image });
    };

    const isDisabled = quantity === 0 || !quantity || !size || !colour;

    return (
        <Root>
            <Details>
                <ItemImage src={image} onClick={handleClickImage} />
                <Text>
                    <ItemName>{name}</ItemName>
                    <ItemPrice>{price}</ItemPrice>
                </Text>
            </Details>
            <SelectMenu
                value={size ? [size] : []}
                options={mapOptions(sizes)}
                onChange={handleSizeChange}
            />
            <SelectMenu
                value={colour ? [colour] : []}
                options={mapOptions(colours)}
                onChange={handleColourChange}
            />
            <Numberfield value={quantity} onChange={handleQuantityChange} />
            <Textfield value={`$${cost}`} disabled onChange={() => {}} />
            <Button type="primary" disabled={isDisabled} onClick={handleClickAdd}>
                Add
            </Button>
        </Root>
    );
};

const Root = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    border-top: 1px solid #e5e5e5;
    align-items: center;
    grid-column-gap: 16px;
    > * {
        margin: 0;
    }
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
`;

const ItemName = styled.span``;

const ItemPrice = styled.span`
    ::before {
        content: '$';
    }
`;

const ItemImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
`;

export default OrderableItem;
