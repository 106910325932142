import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import OrdersList from '../components/Orders';

const Orders: React.FC<any> = () => {
    return (
        <Root>
            <StyledOrdersList all />
        </Root>
    );
};

const Root = styled.div`
    margin: 16px 16px;
    height: 100%;
    width: 100%;
    overflow: scroll;
`;

const StyledOrdersList = styled(OrdersList)`
    margin: 0 -16px;
    margin-bottom: 16px;
`;

export default Orders;
