import React from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

type Props = {
    name: string;
    email: string;
    campaignId: string;
};

const User: React.FC<Props> = ({ name, email, campaignId }) => {
    const CAMPAIGN_QUERY = gql`
    {
        campaignById(_id: "${campaignId}"){
            name
            image
            items {
              _id
            }
        }
    }
    `;
    const { loading, data, refetch } = useQuery(CAMPAIGN_QUERY);

    if (loading) {
        return null;
    }

    return (
        <Root>
            <Text>{name}</Text>
            <Text>{email}</Text>
            <Text>{data.campaignById.name}</Text>
        </Root>
    );
};

const Root = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 8px;
`;

const Text = styled.div``;

export default User;
