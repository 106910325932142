import React from 'react';
import styled from 'styled-components';

type Props = {
    value: number;
    label?: string;
    placeholder?: string;
    className?: string;
    onChange(value: number | undefined): void;
};

const Numberfield: React.FC<Props> = ({ value, label, placeholder, className, onChange }) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace('-', '');
        onChange(parseInt(val));
    };

    return (
        <Root className={className}>
            {label && <Label>{label}</Label>}
            <Input
                value={value}
                placeholder={placeholder}
                hasLabel={!!label}
                onChange={handleOnChange}
                type="number"
                min="0"
                pattern="\d+"
            />
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    flex: 1;
    height: 38px;
    min-width: 0;
`;

const Input = styled.input<{ hasLabel: boolean }>`
    margin-top: ${(p) => (p.hasLabel ? '8px' : undefined)};
    border: 1px solid ${(p) => p.theme.colors.fieldBorder};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    height: 38px;
    min-width: 0;
    ::placeholder {
        color: ${(p) => p.theme.colors.placeholderText};
    }
`;

const Label = styled.span`
    font-size: 12px;
`;

export default Numberfield;
