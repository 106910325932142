import { createApolloFetch, FetchResult } from 'apollo-fetch';
import createObjectId from './create-object-id';
import { Status } from '../types';

const fetch = createApolloFetch({
    uri: 'https://j7jio43s9k.execute-api.ap-southeast-2.amazonaws.com/prod/graphql',
});

const updateOrderStatus = (orderId: string, status: Status) => {
    //mutation updateOrderStatus($orderId: MongoID!, $status: String!, $dateTime: Date!) {
    const query = `
        mutation updateOrderStatus($orderId: MongoID!, $status: String!) {
            orderUpdateStatusById(_id: $orderId, status: $status) {
                _id
                status
            }
        }
    `;

    const variables = {
        orderId: createObjectId(orderId),
        status: status,
        dateTime: new Date(),
    };

    return fetch({
        query,
        variables,
    });
};

export const getActionForStatus = (
    status: Status,
): { action: string; fn(orderId: string): Promise<FetchResult> } => {
    switch (status) {
        case 'created':
            return {
                action: 'Accept Order',
                fn: acceptOrder,
            };
        case 'pending':
            return {
                action: 'Request Payment',
                fn: requestPayment,
            };
        case 'payment requested':
            return {
                action: 'Mark as Paid',
                fn: markOrderPaid,
            };
        case 'awaiting shipping':
            return {
                action: 'Mark as Shipped',
                fn: markOrderShipped,
            };
        case 'in transit':
            return {
                action: 'Complete Order',
                fn: completeOrder,
            };
    }
};

export const cancelOrder = (orderId: string): Promise<FetchResult> => {
    return updateOrderStatus(orderId, 'cancelled');
};

export const acceptOrder = (orderId: string) => {
    return updateOrderStatus(orderId, 'pending');
};

export const requestPayment = async (orderId: string) => {
    // await createCustomer();
    return updateOrderStatus(orderId, 'payment requested');
};

export const markOrderPaid = (orderId: string) => {
    return updateOrderStatus(orderId, 'awaiting shipping');
};

export const markOrderShipped = (orderId: string) => {
    return updateOrderStatus(orderId, 'in transit');
};

export const completeOrder = (orderId: string) => {
    return updateOrderStatus(orderId, 'complete');
};
