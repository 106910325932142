import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import Icon, { IconType } from './Icon';

interface Props {
    children: any;
    disabled?: boolean;
    type?: string;
    busy?: boolean;
    className?: string;
    icon?: IconType;
    onClick(): void;
}

const Button: React.FC<Props> = ({ children, disabled, icon, type, busy, className, onClick }) => {
    return (
        <ButtonElement
            disabled={disabled}
            buttonType={type}
            className={className}
            onClick={() => onClick()}
        >
            {icon && <StyledIcon type={icon} />}
            <Inner busy={busy}>{children}</Inner>
            {busy && <BusySpinner color="white" />}
        </ButtonElement>
    );
};

const ButtonElement = styled.button<{ buttonType?: string }>`
    position: relative;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 8px 16px;
    background-color: ${(p) =>
        p.buttonType === 'primary' ? p.theme.colors.blue : p.theme.colors.grey300};
    color: ${(p) => (p.buttonType === 'primary' ? p.theme.colors.white : p.theme.colors.black)};
    border-radius: 4px;
    font-size: 12px;
    &:disabled {
        opacity: 0.33;
        cursor: default;
    }
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Inner = styled.div<{ busy?: boolean }>`
    opacity: ${(p) => (p.busy ? 0 : 1)};
`;

const BusySpinner = styled(Spinner)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const StyledIcon = styled(Icon)`
    margin-right: 8px;
`;

export default Button;
