import React from 'react';
import styled from 'styled-components';
import UserMenu from './UserMenu';

const Navigation: React.FC<any> = () => {
    return (
        <Root>
            <List>
                <IdealPromoLogo src="https://i.imgur.com/U3SZqYZ.png" />
                {/* <IdealPromoLogo src="https://i.imgur.com/5qPqseE.png" /> */}
                <UserMenu />
            </List>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding: 0px 8px;
    height: 64px;
`;

const List = styled.ul`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const IdealPromoLogo = styled.img`
    margin-left: 16px;
    height: 64px;
`;

export default Navigation;
