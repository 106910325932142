import React from 'react';
import styled from 'styled-components';

type Props = {
    id: string;
    name: string;
    image: string;
    size: string;
    colour: string;
    cost: number;
    quantity: number;
    onClickEdit(): void;
};

const OrderedItem: React.FC<Props> = ({ name, image, cost, size, colour, quantity }) => {
    return (
        <Root>
            <Details>
                <ItemImage src={image} />
                <Text>
                    <ItemName>{name}</ItemName>
                    <ItemPrice>{cost / quantity}</ItemPrice>
                </Text>
            </Details>
            <ItemText>{size}</ItemText>
            <ItemText>{colour}</ItemText>
            <ItemText>{quantity}</ItemText>
            <ItemText>${cost}</ItemText>
        </Root>
    );
};

const Root = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    border-top: 1px solid #e5e5e5;
    align-items: center;
    grid-column-gap: 16px;
    > * {
        margin: 0;
    }
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
`;

const ItemName = styled.span``;

const ItemPrice = styled.span`
    ::before {
        content: '$';
    }
`;

const ItemImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
`;

const ItemText = styled.span``;

export default OrderedItem;
