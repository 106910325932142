import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Textfield from './Textfield';
import ImageUploader from './ImageUploader';
import Button from './Button';
import ColourField from './ColourField';
import SelectMenu from './SelectMenu';
import createObjectId from '../utils/create-object-id';

const CREATE_ITEM = gql`
    mutation createItem(
        $campaignId: String!
        $name: String!
        $price: Float!
        $image: String!
        $colours: [String!]
        $sizes: [String!]
    ) {
        itemCreateOne(
            record: {
                campaignId: $campaignId
                name: $name
                image: $image
                price: $price
                colours: $colours
                sizes: $sizes
            }
        ) {
            record {
                _id
            }
        }
    }
`;

const EDIT_ITEM = gql`
    mutation editItem(
        $itemId: MongoID!
        $name: String!
        $price: Float!
        $image: String!
        $colours: [String!]
        $sizes: [String!]
    ) {
        itemUpdateById(
            record: {
                _id: $itemId
                name: $name
                image: $image
                price: $price
                colours: $colours
                sizes: $sizes
            }
        ) {
            record {
                _id
            }
        }
    }
`;

type Props = {
    itemId?: string;
    campaignId: string | undefined;
    onConfirm(): void;
};

const ItemCreator: React.FC<Props> = ({ itemId, campaignId, onConfirm }) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('$');
    const [colours, setColours] = useState<string[]>([]);
    const [image, setImage] = useState('');
    const [sizes, setSizes] = useState<string[]>([]);
    const [creating, setCreating] = useState(false);
    const [createItem, { data }] = useMutation(CREATE_ITEM);
    const [editItem, { data: editedData }] = useMutation(EDIT_ITEM);

    const ITEM_QUERY = gql`
        {
        itemById(_id: "${itemId}") {
            name
            image
            price
            colours
            sizes
        }
        }
    `;

    const { loading, error, data: itemData } = useQuery(ITEM_QUERY);

    const sizesMapped = useMemo(
        () =>
            sizes.map((s) => {
                return { label: s, value: s };
            }),
        [sizes],
    );

    useEffect(() => {
        if (editedData || data) {
            setCreating(false);
            onConfirm();
        }
    }, [editedData, data, onConfirm, setCreating]);

    useEffect(() => {
        if (!error && itemData) {
            const { itemById: item } = itemData;
            setName(item.name);
            setImage(item.image);
            setPrice(`$${item.price}`);
            setColours(item.colours);
            setSizes(item.sizes);
        }
    }, [itemData, error]);

    const resetFields = () => {
        setName('');
        setImage('');
        setPrice('$');
        setColours([]);
        setSizes([]);
    };

    const handleChangeSizes = (value: { value: string; label: string }[]) => {
        const newSizes = value.map((s) => s.value);
        setSizes(newSizes);
    };

    const handleCreateItem = () => {
        setCreating(true);
        const floatPrice = parseFloat(price.substring(1));
        if (!itemId) {
            createItem({
                variables: {
                    campaignId: campaignId,
                    name: name,
                    image: image,
                    price: floatPrice,
                    sizes: sizes,
                    colours: colours,
                },
            });
            resetFields();
        }
    };

    const handleEditItem = () => {
        setCreating(true);
        const floatPrice = parseFloat(price.substring(1));
        editItem({
            variables: {
                itemId: createObjectId(itemId),
                name: name,
                image: image,
                price: floatPrice,
                sizes: sizes,
                colours: colours,
            },
        });
    };

    return (
        <Root>
            <ItemNameField label="Item Name" value={name} onChange={(n) => setName(n)} />
            <ImageUploader
                value={image}
                onUpload={(url: string) => setImage(url)}
                onClear={() => setImage('')}
            />
            <Textfield label="Price" value={price} onChange={(n) => setPrice(n)} />
            <SelectMenu
                label="Sizes"
                value={sizesMapped}
                options={[]}
                placeholder="Enter a size to create eg. XL or 32"
                onChange={handleChangeSizes}
                isMulti
                isCreatable
            />
            <ColourField value={colours} onChange={(c) => setColours(c)} />
            <CreateButton
                type="primary"
                busy={creating}
                onClick={itemId ? handleEditItem : handleCreateItem}
            >
                {itemId ? 'Update Item' : 'Create Item'}
            </CreateButton>
        </Root>
    );
};

const Root = styled.div``;

const ItemNameField = styled(Textfield)`
    margin-top: 0;
`;

const CreateButton = styled(Button)`
    margin-top: 24px;
`;

export default ItemCreator;
