import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import AddUser from '../components/AddUser';
import Header from '../components/Header';
import TableHeading from '../components/TableHeading';
import User from '../components/User';
import List from '../components/List';
import Modal from '../components/Modal';

const USERS_QUERY = gql`
    {
        userMany(filter: {}) {
            _id
            name
            email
            campaignId
        }
    }
`;

const Users: React.FC<any> = () => {
    const [addingUser, setAddingUser] = useState(false);
    const { loading, data, refetch } = useQuery(USERS_QUERY);

    const UserItems =
        data &&
        data.userMany.map((u) => {
            return <User key={u._id} {...u} />;
        });

    const handleClickAddUser = () => {
        setAddingUser(true);
    };

    const handleCloseModal = () => {
        // clear state
        setAddingUser(false);
        refetch();
    };

    return (
        <Root>
            <Header>Users</Header>
            <CreateNew onClick={handleClickAddUser}>+ Add User</CreateNew>
            <TableHeading gridTemplateColumns="1fr 1fr 1fr">
                <HeadingItem>Name</HeadingItem>
                <HeadingItem>Email</HeadingItem>
                <HeadingItem>Campaign</HeadingItem>
            </TableHeading>
            <List items={data && UserItems} loading={loading} emptyState="No Users" />
            <Modal open={addingUser} title="Add User" onClose={handleCloseModal}>
                <AddUser onAdd={handleCloseModal} />
            </Modal>
        </Root>
    );
};

const Root = styled.div`
    position: relative;
    padding-bottom: 16px;
    margin: 16px 16px;
    /* height: 100%; */
    width: 100%;
    overflow: scroll;
`;

const HeadingItem = styled.div``;

const CreateNew = styled.span`
    position: absolute;
    line-height: 38px;
    right: 0;
    top: 0;
    cursor: pointer;
    /* text-decoration: underline; */
    color: ${(p) => p.theme.colors.blue};
    :visited {
        color: black;
    }
`;

export default Users;
