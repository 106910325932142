export type ThemeColor = keyof ReturnType<typeof lightTheme>;
export type ThemeColors = ReturnType<typeof lightTheme>;
export type ThemeKey = 'light' | 'dark';
export type Theme = typeof common & {
    colors: { [K in ThemeColor]: string };
};

export const common = {
    key: '',
    fonts: {
        sizeLarge: 18,
        sizeMedium: 16,
        sizeDefault: 14,
        sizeSmall: 12,
        sizeTiny: 10,
        weightBold: 'bold',
        weightNormal: 'normal',
    },
    input: {
        small: {
            borderRadius: 4,
            minHeight: 32,
            paddingY: 6,
            paddingX: 8,
            lineHeight: 1.3,
            fontSize: 14,
            fontWeight: undefined,
            borderWidth: 1,
            iconXY: 18,
            iconPadding: 7,
        },
        medium: {
            borderRadius: 4,
            minHeight: 40,
            paddingY: 10,
            paddingX: 12,
            lineHeight: 1.3,
            fontSize: 14,
            fontWeight: undefined,
            borderWidth: 1,
            iconXY: 24,
            iconPadding: 8,
        },
        large: {
            borderRadius: 4,
            minHeight: 48,
            paddingY: 14,
            paddingX: 16,
            lineHeight: 1.3,
            fontSize: 14,
            fontWeight: undefined,
            borderWidth: 1,
            iconXY: 24,
            iconPadding: 12,
        },
        ludicrous: {
            borderRadius: 4,
            minHeight: 48,
            paddingY: 14,
            paddingX: 16,
            lineHeight: 1.3,
            fontSize: 20,
            fontWeight: 'bold',
            borderWidth: 1,
            iconXY: 24,
            iconPadding: 12,
        },
    },
    select: {
        minHeight: 40,
        paddingY: 7,
        paddingX: 12,
        iconXY: 24,
        iconPadding: 8,
    },
    selectList: { itemY: 40, paddingY: 4 },
    choiceList: { choiceXY: 18, choiceMarginLeft: 8 },
    mediaEditor: { itemX: 60, itemY: 60 },
    dimensions: {
        spacingWide: 40,
        spacingMedium: 24,
        spacingSmall: 8,
        spacingTiny: 4,
        borderRadiusInput: 2,
        dragCloneX: 250,
        dragCloneBorder: 6,
        richTextMaxWidth: 720,
    },
    zIndexes: {
        richTextEditor: 2,
        richTextToolbar: 1,
        richTextToolbarSticky: 10,
        richTextPopover: 11,
        popover: 50,
        message: 60,
        modal: 100,
        modalSaving: 12,
        itemToolBar: 20,
        themeSwitcher: 100,
        layoutHeader: 100,
        siteSelector: 100,
        richTextEditorFullscreen: 100,
        toolTip: 100,
        drawerCover: 101,
        drawer: 102,
        dragging: 1000,
    },
    linkPopup: {
        width: 340,
    },
    anim: {
        siteSelector: { dur: 200 },
        linkPopup: { dur: 150 },
        popover: { dur: 150, scale: 0.95 },
    },
    type: {
        headingOne: { fontSize: 48, lineHeight: 64, letterSpacing: -0.5, fontWeight: 600 },
        headingTwo: { fontSize: 36, lineHeight: 48, letterSpacing: 0, fontWeight: 600 },
        headingThree: { fontSize: 24, lineHeight: 32, letterSpacing: 0, fontWeight: 600 },
        headingFour: { fontSize: 21, lineHeight: 28, letterSpacing: 0, fontWeight: 500 },
        subTitleOne: { fontSize: 18, lineHeight: 24, letterSpacing: 0, fontWeight: 600 },
        subTitleTwo: { fontSize: 16, lineHeight: 24, letterSpacing: 0, fontWeight: 600 },
        subTitleThree: {
            fontSize: 14,
            lineHeight: 20,
            letterSpacing: 0.25,
            fontWeight: 600,
        },
        bodyLarge: { fontSize: 18, lineHeight: 24, letterSpacing: 0, fontWeight: 400 },
        bodyBase: { fontSize: 16, lineHeight: 24, letterSpacing: 0, fontWeight: 400 },
        bodySmall: { fontSize: 14, lineHeight: 20, letterSpacing: 0, fontWeight: 400 },
        button: { fontSize: 14, lineHeight: 20, letterSpacing: 0.5, fontWeight: 600 },
        captionOne: { fontSize: 12, lineHeight: 16, letterSpacing: 0.25, fontWeight: 400 },
        captionTwo: { fontSize: 12, lineHeight: 16, letterSpacing: 0.25, fontWeight: 600 },
        overline: { fontSize: 10, lineHeight: 16, letterSpacing: 1, fontWeight: 600 },
    },
    stickyHeaderHeight: {
        layoutHeader: 64,
        toolBar: 64,
        rteToolBar: 40,
    },
    colors: {
        black: '#000000',
        white: '#FFFFFF',
        brand: '#1890FF',
        listDivider: '#F8F8F8',
        fieldBorder: '#CCCCCC',
        placeholderText: '#999999',
        backgroundSecondary: '#f5f5f5',
        textSecondary: '#777777',
        idealPink: '#ED038C',
        idealPinkFaded: '#EDB2D4',
        idealOrange: '#F15929',
        idealOrangeFaded: '#F1C0B1',
        idealYellow: '#FBB03E',
        divider: '#eeeeee',
        grey100: '#F5F5F5',
        grey200: '#E5E5E5',
        grey300: '#D6D6D6',
        grey400: '#ABABAB',
        grey500: '#737373',
        grey600: '#333333',
        grey700: '#222222',
        grey800: '#111111',
        green200: '#F2FCF2',
        green300: '#CCF3CC',
        green400: '#008000',
        green500: '#00C200',
        amber200: '#FFF9F2',
        amber300: '#FFEACC',
        amber400: '#B16800',
        amber500: '#FF9500',
        red500: '#FF4D4D',
        blue: '#007AFF',
        blue500: '#DBE9FF',
        teal: '#0EC6DC',
        mint: '#21DBAC',
        purple: '#6200EE',
        yellow: '#FFE200',
        blueTransparent100: 'rgba(24, 144, 255, 0.10)',
        blueTransparent400: 'rgba(24, 144, 255, 0.40)',
        blackTransparent100: 'rgba(0, 0, 0, 0.04)',
        blackTransparent200: 'rgba(0, 0, 0, 0.10)',
        blackTransparent300: 'rgba(0, 0, 0, 0.33)',
        blackTransparent400: 'rgba(0, 0, 0, 0.55)',
        blackTransparent500: 'rgba(0, 0, 0, 0.80)',
        whiteTransparent100: 'rgba(255, 255, 255, 0.04)',
        whiteTransparent200: 'rgba(255, 255, 255, 0.10)',
        whiteTransparent300: 'rgba(255, 255, 255, 0.33)',
        whiteTransparent400: 'rgba(255, 255, 255, 0.55)',
        whiteTransparent500: 'rgba(255, 255, 255, 0.80)',
        transparent: 'transparent',
    },
    brandColors: {
        facebook: '#3B5998',
        twitter: '#49A1F2',
        pinterest: '#C8222C',
        instagram: '#AB2DB4',
    },
};

const lightTheme = () => {
    const c = common.colors;

    return {
        ...c,
    };
};

const getTheme = (key: ThemeKey) => ({
    ...common,
});

const light = getTheme('light');

const themes: { [theme: string]: Theme } = {
    light,
};

export default themes;
