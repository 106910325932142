import React from 'react';
import styled from 'styled-components';
import ItemList from '../components/ItemList';
import Orders from '../components/Orders';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const Campaign: React.FC<any> = () => {
    const { id } = useParams();
    const CAMPAIGN_QUERY = gql`
    {
        campaignById(_id: "${id}"){
            name
            image
            items {
              _id
            }
        }
        orderMany(filter: {campaignId: "${id}"}) {
            _id
        }
    }
    `;

    const { loading, error, data } = useQuery(CAMPAIGN_QUERY);
    if (error) return <p>Error :(</p>;
    return (
        <Root>
            <Details>
                <CampaignImage src={loading ? '' : data.campaignById.image} />
                <Header>{loading ? 'Campaign name' : data.campaignById.name}</Header>
            </Details>
            <Main>
                <Orders campaignId={id} />
                <ItemList campaignId={id} />
            </Main>
        </Root>
    );
};

const Root = styled.div`
    height: 100%;
    width: 100%;
    overflow: scroll;
`;

const Details = styled.div`
    padding: 0 16px;
    display: flex;
    align-items: center;
`;

const CampaignImage = styled.img`
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 4px;
`;

const Main = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1439px) {
        flex-direction: column;
    }
    border-top: 1px solid #ccc;
    /* padding: 0 32px; */
    min-height: calc(100% - 71px);
`;

export default Campaign;
