import React from 'react';
import styled from 'styled-components';
import { common } from '../Theme';
import { Status } from '../types';

type Props = {
    type: Status;
};

const Badge: React.FC<Props> = ({ type }) => {
    const _type = type === 'payment requested' ? 'invoiced' : type;
    return <Root type={type}>{_type.toUpperCase()}</Root>;
};

const getBackgroundColour = (type: Status) => {
    switch (type) {
        case 'created':
            return common.colors.blue500;
        case 'pending':
            return common.colors.idealOrangeFaded;
        case 'payment requested':
            return common.colors.idealPinkFaded;
        case 'complete':
            return common.colors.green300;
        default:
            return common.colors.grey200;
    }
};

const getTextColour = (type: Status) => {
    switch (type) {
        case 'created':
            return common.colors.blue;
        case 'pending':
            return common.colors.idealOrange;
        case 'payment requested':
            return common.colors.idealPink;
        case 'complete':
            return common.colors.green500;
        default:
            return common.colors.grey500;
    }
};

const Root = styled.span<{ type: Status }>`
    background-color: ${(p) => getBackgroundColour(p.type)};
    color: ${(p) => getTextColour(p.type)};
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 6px;
    max-height: 12px;
`;

export default Badge;
