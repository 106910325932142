import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { useAuth0 } from '../Auth';

const Sidebar: React.FC<any> = () => {
    const { loading, isAdmin } = useAuth0();
    return (
        <Root>
            <List>
                <ListItem>
                    <StyledLink to="/">
                        <Icon type="home" />
                    </StyledLink>
                </ListItem>
                {isAdmin && (
                    <>
                        <ListItem>
                            <StyledLink to="/users">
                                <Icon type="campaign" />
                            </StyledLink>
                        </ListItem>
                        <ListItem>
                            <StyledLink to="/orders">
                                <Icon type="file" />
                            </StyledLink>
                        </ListItem>
                    </>
                )}
            </List>
        </Root>
    );
};

const Root = styled.div`
    background: ${(p) => p.theme.colors.backgroundSecondary};
    padding: 8px 16px;
    @media print {
        display: none;
    }
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;
    justify-content: space-between;
    /* align-items: center; */
`;

const ListItem = styled.li`
    text-decoration: none;
    margin-top: 16px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${(p) => p.theme.colors.textSecondary};
    :visited {
        color: ${(p) => p.theme.colors.textSecondary};
    }
    font-weight: 600;
`;

export default Sidebar;
