import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ItemCreator from './ItemCreator';
import Modal from './Modal';
import List from './List';
import Item from './Item';

type Props = {
    campaignId: string | undefined;
};

const ItemList: React.FC<Props> = ({ campaignId }) => {
    const [creatingItem, setCreatingItem] = useState(false);
    const [editingItem, setEditingItem] = useState(false);
    const [editingItemId, setEditingItemId] = useState();
    const ITEMS_QUERY = gql`
        {
            itemMany(filter: {campaignId: "${campaignId}"}) {
                _id
                name
                image
                price
                sizes
                colours
                deleted
            }
        }
    `;

    const { loading, data, refetch } = useQuery(ITEMS_QUERY);

    useEffect(() => {
        if (!creatingItem && !editingItem) {
            // should not do this on the first render. Fix this.
            console.log('Refetching items');
            refetch();
        }
    }, [creatingItem, editingItem, refetch]);

    const handleOnItemEdit = (item) => {
        const { _id } = item;
        setEditingItem(true);
        setEditingItemId(_id);
    };
    const items = data && data.itemMany ? data.itemMany : ([] as string[]);

    const nonDeletedItems = items.filter((i) => !i.deleted);

    const itemsElements = nonDeletedItems
        ? nonDeletedItems.map((i) => (
              <Item
                  key={i._id}
                  name={i.name}
                  id={i._id}
                  price={i.price}
                  image={i.image}
                  sizes={i.sizes}
                  colours={i.colours}
                  onEdit={() => handleOnItemEdit(i)}
                  onDelete={() => refetch()}
              />
          ))
        : [];

    const handleCreateItem = () => {
        setCreatingItem(false);
        setEditingItem(false);
    };

    return (
        <>
            <Root>
                <Header>Available Items</Header>
                <CreateButton onClick={() => setCreatingItem(true)}>+ Create New Item</CreateButton>
                <StyledList items={itemsElements} loading={loading} emptyState="No Items" />
            </Root>
            <Modal open={creatingItem} title="Add Item" onClose={() => setCreatingItem(false)}>
                <ItemCreator campaignId={campaignId} onConfirm={handleCreateItem} />
            </Modal>
            <Modal open={editingItem} title="Edit Item" onClose={() => setEditingItem(false)}>
                <ItemCreator
                    itemId={editingItemId}
                    campaignId={campaignId}
                    onConfirm={handleCreateItem}
                />
            </Modal>
        </>
    );
};

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 30%;
    /* min-width: 30%; */
    /* margin-top: 32px; */
    background: ${(p) => p.theme.colors.backgroundSecondary};
    @media (max-width: 1439px) {
        background: white;
    }
    padding: 16px;
    min-height: 100%;
    overflow: scroll;
`;

const StyledList = styled(List)`
    margin-top: 24px;
`;

const Header = styled.h2`
    margin: 0;
`;

const CreateButton = styled.span`
    color: ${(p) => p.theme.colors.blue};
    position: absolute;
    right: 16px;
    line-height: 28px;
    cursor: pointer;
`;

export default ItemList;
