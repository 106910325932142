import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { openUploadWidget } from '../utils/cloudinary';

type Props = {
    value: string;
    onClear(): void;
    onUpload(url: string): void;
};

const ImageUploader: React.FC<Props> = ({ value, onUpload, onClear }) => {
    const onClickUpload = () => {
        const uploadOptions = {
            cloudName: 'do1tcwro9',
            tags: [''],
            uploadPreset: 'zd8ypsw4',
        };

        openUploadWidget(uploadOptions, (error, photos) => {
            if (!error) {
                if (photos.event === 'success') {
                    onUpload(photos.info.secure_url);
                }
            } else {
                console.log(error);
            }
        });
    };

    return (
        <Root>
            <Label>Upload image</Label>
            <Main>
                {value && (
                    <ClearIcon onClick={onClear}>
                        <Icon type="close" />
                    </ClearIcon>
                )}
                {value ? (
                    <Preview src={value} />
                ) : (
                    <UploadHelper onClick={onClickUpload}>
                        <Icon type="photo" />
                        <UploadText>
                            Click <UnderlinedWord>here</UnderlinedWord> to upload
                        </UploadText>
                    </UploadHelper>
                )}
            </Main>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

const Main = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    width: 256px;
    height: 256px;
    border: 1px dashed ${(p) => p.theme.colors.grey300};
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.backgroundSecondary};
`;

const UploadHelper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(p) => p.theme.colors.placeholderText};
    align-items: center;
    cursor: pointer;
`;

const UploadText = styled.span`
    margin-top: 8px;
`;

const Label = styled.span`
    font-size: 12px;
`;

const Preview = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
`;

const UnderlinedWord = styled.span`
    text-decoration: underline;
`;

const ClearIcon = styled.div`
    position: absolute;
    cursor: pointer;
    right: 2px;
    top: 2px;
    color: ${(p) => p.theme.colors.placeholderText};
`;

export default ImageUploader;
