import React, { useEffect } from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Icon from './Icon';
import createObjectId from '../utils/create-object-id';

type Props = {
    id: string;
    name: string;
    price: number;
    image: string;
    sizes: string[];
    colours: string[];
    onEdit(id: string): void;
    onDelete(): void;
};

const DELETE_ITEM = gql`
    mutation deleteItem($id: MongoID!) {
        itemUpdateById(record: { _id: $id, deleted: true }) {
            record {
                _id
                deleted
            }
        }
    }
`;

const Item: React.FC<Props> = ({ id, name, price, image, onEdit, onDelete }) => {
    const [deleteItem, { data }] = useMutation(DELETE_ITEM);

    useEffect(() => {
        if (data && data.itemRemoveById) {
            onDelete();
        }
    }, [data, onDelete]);

    const handleClickDelete = (e: any) => {
        e.stopPropagation();
        deleteItem({ variables: { id: createObjectId(id) } });
    };

    return (
        <Root key={id} onClick={() => onEdit(id)}>
            <Details>
                <ItemImage src={image} />
                <Text>
                    <ItemName>{name}</ItemName>
                    <ItemPrice>${price}</ItemPrice>
                </Text>
            </Details>
            <Controls>
                <IconWrapper onClick={handleClickDelete}>
                    <Icon type="trash" />
                </IconWrapper>
            </Controls>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid ${(p) => p.theme.colors.fieldBorder};
    border-radius: 4px;
    background: ${(p) => p.theme.colors.white};
    margin-top: 24px;
    cursor: pointer;
`;

const ItemImage = styled.img`
    width: 50px;
    border-radius: 4px;
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`;

const ItemText = styled.span`
    font-weight: 500;
    font-size: 14px;
`;

const ItemName = styled(ItemText)``;

const ItemPrice = styled(ItemText)`
    margin-top: 4px;
`;

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 900;
    line-height: 0;
    color: ${(p) => p.theme.colors.textSecondary};
`;

const IconWrapper = styled.div`
    margin-left: 24px;
`;

export default Item;
