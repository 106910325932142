import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '../Auth';
import Icon from './Icon';
import Button from './Button';

const UserMenu: React.FC<any> = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { isAuthenticated, loginWithRedirect, logout, loading, user, updateUser } = useAuth0();
    const handleClickUser = () => {
        setMenuOpen((o) => !o);
    };

    return (
        <Root>
            <Email>{isAuthenticated && user && `User: ${user.name}`}</Email>
            {isAuthenticated && <Button onClick={() => logout()}>Log out</Button>}
            {/* <UserIcon onClick={handleClickUser}>
                {user && user.name ? (
                    <> {user.name.substring(0, 2).toUpperCase()}</Bu>
                ) : (
                    <Icon type="user" />
                )}
            </UserIcon>
            <Menu open={menuOpen}>
                {!isAuthenticated && !loading && (
                    <MenuItem onClick={() => loginWithRedirect({})}>Log in</MenuItem>
                )}
               v
            </Menu> */}
        </Root>
    );
};

const Root = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media print {
        display: none;
    }
`;

const Email = styled.div`
    margin-right: 8px;
`;

const UserIcon = styled.div`
    display: flex;
    background-color: ${(p) => p.theme.colors.idealPink};
    color: white;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

const Menu = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    position: absolute;
    right: 0;
    bottom: -42px;
    opacity: ${(p) => (p.open ? 1 : 0)};
    display: ${(p) => (p.open ? 'visible' : 'none')};
    width: 124px;
    z-index: 999;
`;

const MenuItem = styled.div`
    display: flex;
    background: white;
    padding: 8px;
`;

export default UserMenu;
