import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

type Props = {
    open: boolean;
    title: string;
    children: any;
    maxWidth?: number;
    onClose(): void;
};

const Modal: React.FC<Props> = ({ open, title, children, maxWidth, onClose }) => {
    if (!open) return null;
    return (
        <>
            <Cover cover={open} onClick={onClose} />
            <Root open={open} maxWidth={maxWidth}>
                <Header>
                    <Title>{title}</Title>
                    <Close onClick={onClose}>
                        <Icon type="close" />
                    </Close>
                </Header>
                <Main>{children}</Main>
                {/* <Controls>

                </Controls> */}
            </Root>
        </>
    );
};

const Root = styled.div<{ open: boolean; maxWidth?: number }>`
    display: flex;
    opacity: ${(p) => (p.open ? 1 : 0)};
    display: ${(p) => (p.open ? 'block' : 'none')};
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 50%;
    border: 1px solid #ccc;
    left: 25%;
    top: 1%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 8px;
    max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : undefined)};
    /* min-height: 400px; */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 16px 16px;
`;

const Title = styled.h3`
    margin: 0;
`;

const Close = styled.div`
    cursor: pointer;
`;

const Main = styled.div`
    height: 100%;
    padding: 16px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
`;

const Cover = styled.div<{ cover: boolean }>`
    opacity: ${(p) => (p.cover ? 1 : 0)};
    visibility: ${(p) => (p.cover ? 'visible' : 'hidden')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease-out;
`;

export default Modal;
