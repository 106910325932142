import React from 'react';
import styled from 'styled-components';

type Props = {
    value: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    onChange(e: any): void;
};

const Textfield: React.FC<Props> = ({
    value,
    label,
    placeholder,
    disabled,
    className,
    onChange,
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
    return (
        <Root className={className}>
            {label && <Label>{label}</Label>}
            <Input
                value={value}
                placeholder={placeholder}
                hasLabel={!!label}
                disabled={disabled}
                onChange={handleOnChange}
            />
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: 110px;
    flex: 1;
`;

const Input = styled.input<{ hasLabel: boolean }>`
    margin-top: ${(p) => (p.hasLabel ? '8px' : undefined)};
    border: 1px solid ${(p) => p.theme.colors.fieldBorder};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    height: 20px;
    min-width: 0;
    ::placeholder {
        color: ${(p) => p.theme.colors.placeholderText};
    }
`;

const Label = styled.span`
    font-size: 12px;
`;

export default Textfield;
