import React from 'react';

export const CartContext = React.createContext([]);

export const CartProvider: React.FC<any> = ({ children }) => {
    const [cart, setCart] = React.useState([]);

    return <CartContext.Provider value={[cart, setCart]}>{children}</CartContext.Provider>;
};

export default () => {
    const [cart, setCart] = React.useContext(CartContext);
    return [cart, setCart];
};
