import React from 'react';
import SelectMenu from './SelectMenu';

type Props = {
    value: string[];
    onChange(value: string[]): void;
};

const ColourField: React.FC<Props> = ({ value, onChange }) => {
    const handleOnChange = (value: { label: string; value: string }[]) => {
        if (!value) {
            onChange(undefined);
            return;
        }
        onChange(value.map((v) => v.value));
    };
    const defaultColours = [
        'Red',
        'Blue',
        'Green',
        'Yellow',
        'Black',
        'White',
        'Grey',
        'Pink',
        'Purple',
    ];
    const defaultMapped = defaultColours.map((c) => {
        return { value: c, label: c };
    });
    const valueMapped = value.map((v) => {
        return { value: v, label: v };
    });
    return (
        <SelectMenu
            label="Colours"
            value={valueMapped}
            options={defaultMapped}
            isMulti
            isCreatable
            onChange={handleOnChange}
        />
    );
};

export default ColourField;
