import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';
import List from './List';
import Icon from './Icon';
import { OrderItem } from '../types';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { useAuth0 } from '../Auth';
import useCart from '../hooks/useCart';

const CREATE_ORDER = gql`
    mutation createOrder(
        $campaignId: String!
        $userId: String!
        $status: String!
        $items: [OrderItemsInput!]!
        $dateTime: Date!
        $amount: Float!
    ) {
        orderCreateOne(
            record: {
                campaignId: $campaignId
                userId: $userId
                status: $status
                items: $items
                dateCreated: $dateTime
                lastModified: $dateTime
                amount: $amount
            }
        ) {
            record {
                _id
            }
        }
    }
`;

type Props = {
    campaignId: String;
    onRemoveItem(index: number): void;
    onSubmitOrder(): void;
};

const Cart: React.FC<Props> = ({ campaignId, onSubmitOrder, onRemoveItem }) => {
    const [createOrder, { data }] = useMutation(CREATE_ORDER);
    const { user } = useAuth0();
    const history = useHistory();
    const [cart, setCart] = useCart();

    useEffect(() => {
        if (data) {
            history.push(`/order/${data.orderCreateOne.record._id}`);
            return () => {
                onSubmitOrder();
            };
        }
    }, [data, history, onSubmitOrder]);

    const items = cart.sort((a, b) => a.name.localeCompare(b.name)) || [];

    const total =
        cart.length > 0
            ? items
                  .map((i) => i.cost)
                  .reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;

    const cartItems = items.map((i, index) => (
        <CartItem key={`${i.name}-${i.colour}-${i.size}`} index={index}>
            <CartItemImage src={i.image} />
            <CartItemDetails>
                <CartItemDetailName>{i.name}</CartItemDetailName>
                <CartItemDetail>Size: {i.size}</CartItemDetail>
                <CartItemDetail>Colour: {i.colour}</CartItemDetail>
                <CartItemDetail>QTY: {i.quantity}</CartItemDetail>
                <CartItemDetail>Cost: ${i.cost}</CartItemDetail>
            </CartItemDetails>
            <CartItemControls>
                <IconWrapper onClick={() => onRemoveItem(index)}>
                    <Icon type="trash" />
                </IconWrapper>
            </CartItemControls>
        </CartItem>
    ));

    const handleSubmitOrder = async () => {
        // await createOrder({
        //     variables: {
        //         campaignId: campaignId,
        //         userId: user.email,
        //         status: 'created',
        //         items: items,
        //         dateTime: new Date(),
        //         amount: total,
        //     },
        // });
        history.push(`/confirm/${campaignId}`);
        // navigate to /shipping/orderId
    };

    const isSubmitDisabled = items.length < 1;

    return (
        <Root>
            <Header>Cart</Header>
            <Total>Total ${total}</Total>
            <StyledList items={cartItems} loading={false} emptyState={'Add items to your cart.'} />
            <Controls>
                <SubmitButton
                    type="primary"
                    disabled={isSubmitDisabled}
                    onClick={handleSubmitOrder}
                >
                    Continue to shipping and confirmation
                </SubmitButton>
            </Controls>
        </Root>
    );
};

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 25%;
    background: ${(p) => p.theme.colors.backgroundSecondary};
    padding: 16px;
`;

const Header = styled.h2`
    margin: 0;
`;

const Total = styled.span`
    position: absolute;
    right: 16px;
    line-height: 28px;
    cursor: pointer;
`;

const Controls = styled.div`
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    position: absolute;
    width: calc(100% - 32px);
    background: ${(p) => p.theme.colors.backgroundSecondary};
    height: 32px;
`;

const SubmitButton = styled(Button)`
    /* margin-top: auto; */
    width: 100%;
    /* width: 100%; */
`;

const StyledList = styled(List)`
    margin-top: 16px;
    margin-bottom: 64px;
`;

const CartItem = styled.div<{ index: number }>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px 0;
    border: 1px solid ${(p) => p.theme.colors.fieldBorder};
    border-radius: 4px;
    background: ${(p) => p.theme.colors.white};
    margin-top: ${(p) => (p.index === 0 ? undefined : '24px')};
    cursor: pointer;
`;

const CartItemImage = styled.img`
    width: 50px;
    border-radius: 4px;
`;

const CartItemDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const CartItemDetailText = styled.span`
    font-size: 14px;
`;

const CartItemDetail = styled(CartItemDetailText)`
    margin-top: 8px;
`;

const CartItemDetailName = styled(CartItemDetailText)`
    font-weight: 600;
`;

const CartItemControls = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 900;
    line-height: 0;
    color: ${(p) => p.theme.colors.textSecondary};
`;

const IconWrapper = styled.div``;

export default Cart;
