import React from 'react';
import styled from 'styled-components';

type Props = {
    children: any;
    className?: string;
};

const Header: React.FC<Props> = ({ children, className }) => {
    return <Root className={className}>{children}</Root>;
};

const Root = styled.h1`
    margin: 16px 0;
`;

export default Header;
