import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

type Props = {
    value: { value: string; label: string }[];
    options: { value: string; label: string }[];
    label?: string;
    placeholder?: string;
    isMulti?: boolean;
    isCreatable?: boolean;
    className?: string;
    isClearable?: boolean;
    onChange(value: any): void;
};

const SelectMenu: React.FC<Props> = ({
    value,
    options,
    label,
    placeholder,
    isMulti = false,
    isCreatable = false,
    onChange,
    ...props
}) => {
    const animatedComponents = makeAnimated();

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            marginTop: `${label ? '8px' : ''}`,
        }),
    };

    if (!isCreatable && value.length === 0 && options.length === 1) {
        onChange(options[0]);
    }

    return (
        <Root className={props.className}>
            {label && <Label>{label}</Label>}
            {isCreatable ? (
                <Creatable
                    value={value}
                    components={animatedComponents}
                    options={options}
                    isMulti={isMulti}
                    onChange={onChange}
                    styles={customStyles}
                    placeholder={placeholder}
                    {...props}
                />
            ) : (
                <Select
                    value={value}
                    components={animatedComponents}
                    options={options}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    styles={customStyles}
                    onChange={onChange}
                    {...props}
                />
            )}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: 110px;
    flex: 1;
`;

const Label = styled.span`
    font-size: 12px;
`;

export default SelectMenu;
