import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Icon from './Icon';

type Props = {
    route?: string;
    text?: string;
    pop?: boolean;
};

const Back: React.FC<any> = ({ text, route, pop }) => {
    const history = useHistory();

    const handleOnClick = pop ? () => history.goBack() : () => history.push(route || '/');

    return (
        <Root onClick={handleOnClick}>
            <Icon type="leftArrow" />
            <Text>{text}</Text>
        </Root>
    );
};

const Root = styled.div`
    color: ${(p) => p.theme.colors.textSecondary};
    display: flex;
    align-items: center;
    cursor: pointer;
    @media print {
        display: none;
    }
`;

const Text = styled.span`
    margin-left: 8px;
`;

export default Back;
