import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import Sidebar from './Sidebar';

type Props = {};

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <Root>
            <Navigation />
            <Main>
                <Sidebar />
                <Children>{children}</Children>
            </Main>
        </Root>
    );
};

const Root = styled.div`
    height: 100vh;
    max-height: 100%;
    overflow: hidden;
    @media print {
        overflow: visible;
    }
`;

const Main = styled.div`
    /* padding: 16px 32px; */
    display: flex;
    height: calc(100% - 64px);
`;

const Children = styled.div`
    display: flex;
    flex: 1;
`;

export default Layout;
