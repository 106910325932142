import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const InvalidRoute: React.FC<any> = () => {
    return (
        <Root>
            <Header>Whoops! Something went wrong.</Header>
            <StyledLink to="/">Click here to go home.</StyledLink>
        </Root>
    );
};

const Root = styled.div`
    margin: 16px 16px;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${(p) => p.theme.colors.textSecondary};
    :visited {
        color: ${(p) => p.theme.colors.textSecondary};
    }
    font-weight: 600;
`;

export default InvalidRoute;
