import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './Auth';
// import config from "./auth_config.json";
import history from './utils/history';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback as () => {}}
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
